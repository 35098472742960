import { selectCustomerActionReasonsState } from '../index';
import { createSelector } from '@ngrx/store';
import { CustomerActionsReasonsState } from '../reducers/customer-action-reasons.reducer';

export const selectCustomerActionReasons = createSelector(
  selectCustomerActionReasonsState,
  (state: CustomerActionsReasonsState) => state && state.customerActionReasons
);
export const selectCancelReasonsLoading = createSelector(
  selectCustomerActionReasonsState,
  (state: CustomerActionsReasonsState) => state && state.loading
);
export const selectCancelReasonsError = createSelector(
  selectCustomerActionReasonsState,
  (state: CustomerActionsReasonsState) => state && state.error
);
